import classNames from 'classnames/bind'
import { FC } from 'react'

import style from './style.m.less'

export interface IPrice {
    price?: string | number
    currency?: string
    white?: boolean
    error?: boolean
    blackCurrency?: boolean
    light?: boolean
}

const cx = classNames.bind(style)

export const Price: FC<IPrice> = ({ price, white, currency, blackCurrency, error, light }) => {
    let formatPrice
    let remainder

    if (price && typeof price === 'string') {
        formatPrice = parseInt(price.split(',')[0], 10)
        remainder = price.split(',')[1] || '00'
    } else if (typeof price === 'number' && !Number.isNaN(price)) {
        formatPrice = Number.isInteger(price) ? price : parseInt(price.toString().split('.')[0], 10)
        remainder = Number.isInteger(price)
            ? '00'
            : price.toString().split('.')[1].substr(0, 2).padEnd(2, '0')
    }
    const className = cx({
        price: true,
        priceWhite: white,
        error: error,
        priceLight: light,
    })
    const classNameCurrency = cx({
        black: blackCurrency,
    })

    return (
        <>
            {typeof price !== undefined && (
                <p className={className}>
                    {formatPrice?.toLocaleString()}
                    {currency !== 'unit' && (
                        <>
                            <span>,{remainder}&nbsp;</span>
                            <span className={classNameCurrency}>{currency || '₽'}</span>
                        </>
                    )}
                </p>
            )}
        </>
    )
}
