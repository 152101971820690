// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".price_Sti0f {\n  font-family: 'SF Pro Display';\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #303440;\n  margin: 0;\n}\n.price_Sti0f span {\n  opacity: 0.5;\n}\n.price_Sti0f span.black_zlg_w {\n  opacity: 1;\n}\n.priceWhite_CAYod {\n  color: #ffffff;\n}\n.priceerror_TOLja {\n  color: #f5222d;\n}\n.priceLight_JlNiZ {\n  font-size: 14px;\n  font-weight: 400;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/modals/CardModal/Details/Price/style.m.less"],"names":[],"mappings":"AAGA;EACI,6BAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,SAAA;AAFJ;AAJA;EASQ,YAAA;AAFR;AAGQ;EACI,UAAA;AADZ;AAKI;EACI,cAAA;AAHR;AAMI;EACI,cAAA;AAJR;AAOI;EACI,eAAA;EACA,gBAAA;AALR","sourcesContent":["@import '/src/assets/style/palette';\n\n// PRICE\n.price {\n    font-family: 'SF Pro Display';\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: @gray-10;\n    margin: 0;\n\n    span {\n        opacity: 0.5;\n        &.black {\n            opacity: 1;\n        }\n    }\n\n    &White {\n        color: @gray-1;\n    }\n\n    &error {\n        color: @red-6;\n    }\n\n    &Light {\n        font-size: 14px;\n        font-weight: 400;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price": "price_Sti0f",
	"black": "black_zlg_w",
	"priceWhite": "priceWhite_CAYod",
	"priceerror": "priceerror_TOLja",
	"priceLight": "priceLight_JlNiZ"
};
export default ___CSS_LOADER_EXPORT___;
